import React from "react";
import { Link } from "react-router-dom";
// Import necessary icons from react-icons
import { FaPhone, FaEnvelope } from "react-icons/fa";

const Head = () => {
  return (
    <>
      <section className="head">
        <div className="container d_flex">
          <div className="left row">
            <FaPhone style={{ margin: "2px 2px 0 0" }} />
            <a href="tel:+254702039048">
              <label style={{ color: "#fff" }}>+254 702 039 048</label>
            </a>
            <FaEnvelope size={20} style={{ padding: "5px 2px 0 0" }} />
            <a href="mailto:info@nonexcomputers.com">
              <label style={{ color: "#fff" }}>info@nonexcomputers.com</label>
            </a>
          </div>
          <div className="right row RText">
            <Link to="/contact" style={{ color: "#fff" }}>
              <label>Theme FAQ"s</label>
              <label>Need Help?</label>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
