import React, { useState } from "react";
import "./style.css";

const Contact = () => {
  return (
    <>
      <section id="contact" className="body-contact">
        <h1 class="section-header">Contact Us</h1>

        <div class="contact-wrapper">
          <div className="form-horizontal">
            <ul class="social-media-list">
              <div className="inner-list">
                <li>
                  <a href="#" target="_blank" class="contact-icon">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </li>
                <a
                  href="https://api.whatsapp.com/send?phone=254702039048&text=Hello%20Nonex%20Computers...,%20"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p> +254 702 039 048</p>
                </a>
              </div>
              <div className="inner-list">
                <li>
                  <a href="#" target="_blank" class="contact-icon">
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <p>
                  <a
                    href="https://www.instagram.com/nonex.computers/"
                    target="_blank"
                  >
                    {" "}
                    nonex.computers
                  </a>
                </p>
              </div>
              <div className="inner-list"></div>
              <div className="inner-list">
                <li>
                  <a href="#" target="_blank" class="contact-icon">
                    <i class="fa-brands fa-facebook"></i>
                  </a>
                </li>
                <a
                  href="https://www.facebook.com/Nonex computers"
                  target="_blank"
                >
                  {" "}
                  <p> Nonex Computers</p>
                </a>
              </div>
              <div className="inner-list">
                <li>
                  <a href="#" target="_blank" class="contact-icon special-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20"
                      width="20"
                      viewBox="0 0 512 512"
                    >
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                    </svg>{" "}
                  </a>
                </li>
                <a
                  href="https://twitter.com/nonex_computers_thika"
                  target="_blank"
                >
                  {" "}
                  <p> nonex_computers_thika</p>
                </a>
              </div>
            </ul>
          </div>
          <div class="direct-contact-container">
            <ul class="contact-list">
              <li class="list-item">
                <div className="" style={{ display: "flex" }}>
                  <div>
                    <i class="fa fa-map-marker fa-2x"></i>
                  </div>
                  <p class="location">
                    Tabby House, groundfloor, Commercial Street, Thika Town,
                    Kenya.
                  </p>
                </div>
              </li>

              <li class="list-item">
                <i class="fa fa-phone fa-2x">
                  <span class="contact-text phone">
                    <a href="tel:+254702039048" title="Give me a call">
                      +254702039048
                    </a>
                  </span>
                </i>
              </li>

              <li class="list-item">
                <i class="fa fa-envelope fa-2x">
                  <span class="contact-text gmail">
                    <a
                      href="mailto:info@nonexcomputers.com"
                      title="Send me an email"
                    >
                      info@nonexcomputers.com
                    </a>
                  </span>
                </i>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
