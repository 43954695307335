import React from "react";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const NotFound = ({ CartItem, addToCart, decreaseQty, removeFromCart }) => {
  return (
    <>
      <section className="comming-body">
        <div className="container">
          <div class="comming-wrapper">
            <h1 style={{ textAlign: "center" }}>
              Coming Soon<span class="dot">.</span>
            </h1>
            <p>
              We are working tirelesly to bring you this, please be considerate.
            </p>
            <Link to="/">
              <div class="icons">Go Back Home</div>
            </Link>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default NotFound;
