import React, { useState } from "react";
import "./style.css";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

const Cart = ({ CartItem, addToCart, decreaseQty, removeFromCart }) => {
  const location = useLocation();
  const { shopItem } = location.state;

  const [selectedImage, setSelectedImage] = useState(shopItem.cover);

  const smallImages = [
    `${shopItem.cover2}`,
    `${shopItem.cover}`,
    `${shopItem.cover3}`,
  ];

  const handleSmallImageClick = (image) => {
    setSelectedImage(image);
  };
  function formatPrice(shopItem) {
    const originalPrice = parseFloat(shopItem);

    const formattedPrice1 = originalPrice.toLocaleString("en-US", {
      style: "currency",
      currency: "KES",
      minimumFractionDigits: 2,
    });

    return formattedPrice1.replace(/KES/g, "").trim();
  }

  const formattedPrice = formatPrice(shopItem.price);
  const wasformattedPrice = formatPrice(shopItem.wasprice);

  const whatsappMessage = `Hello Nonex Computers, I'm interested in the product: ${shopItem.name}.`;

  return (
    <>
      <section className="prod-details-items">
        <div className="container">
          <div className="prod-details-container">
            <div className="left-image">
              <img
                className="home-image"
                src={selectedImage}
                style={{ width: "100%" }}
                alt=""
              />
              <div className="small-images-container">
                {smallImages.map((image, index) => (
                  <img
                    key={index}
                    className={`small-image ${
                      image === selectedImage ? "active" : ""
                    }`}
                    src={image}
                    alt="images on carosel"
                    onClick={() => handleSmallImageClick(image)}
                  />
                ))}
              </div>
            </div>
            <div className="right-descriptions">
              <h1>{shopItem.name}</h1>
              <div style={{ display: "flex" }}>
                <h4
                  className="price"
                  style={{
                    color: "#27ae60",
                  }}
                >
                  Ksh. {formattedPrice}{" "}
                </h4>
                <p
                  style={{
                    color: "#333",
                    margin: "0 0 0 15px",
                  }}
                >
                  <span
                    style={{ textDecoration: "line-through", color: "red" }}
                  >
                    was {wasformattedPrice}
                  </span>
                </p>
              </div>
              <p style={{ display: "flex" }}>
                stock:{" "}
                <p
                  style={{
                    color: `${shopItem.stock > 1 ? "#27ae60" : "#333"}`,
                    margin: "0 0 0 5px",
                  }}
                >
                  {shopItem.stock} items
                </p>
              </p>

              <p style={{ marginBottom: "10px" }}>Description</p>
              <ul className="fantastic-list">
                {shopItem?.description.split(";").map((item, index) => (
                  <li key={index}>{item.trim()}</li>
                ))}
              </ul>
              <div className="inquires">
                <div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=254702039048&text=${encodeURIComponent(
                      whatsappMessage
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btnprimary2">
                      <i
                        class="fa-brands fa-whatsapp"
                        style={{
                          margin: "5px",
                          fontSize: "20px",
                          color: "#fff",
                        }}
                      ></i>
                      Ask For Inquires
                    </button>
                  </a>
                </div>
                <div>
                  <a
                    href="tel:+254702039048"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btnprimary2">
                      <i
                        class="fa-solid fa-phone"
                        style={{
                          margin: "5px",
                          fontSize: "20px",
                          color: "#fff",
                          marginTop: "5px",
                        }}
                      ></i>
                      0702 039 048
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Cart;
