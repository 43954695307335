const PrivacyPolicy = () => {
  return (
    <>
      <section className="privacy-body policy">
        <div className="container">
          <div className="privacy-wrapper">
            <h1 className="bold" style={{ textAlign: "center" }}>
              Privacy Policy
            </h1>

            <h2 className="bold">Information We Collect</h2>
            <ol className="ol">
              <li>
                <p className="italic">
                  Personal Information You Provide to Us:
                </p>
                We receive and store any information you enter on our website or
                provide to us in any other way.
              </li>
              <li>
                <p className="italic">
                  Personal Information Collected Automatically:
                </p>{" "}
                We automatically receive and record information whenever you
                interact with this website, including information collected by
                Google Analytics.
              </li>
              <li>
                <p className="italic">E-mail Communications:</p>
                We may receive a confirmation when you open an e-mail from this
                website, if your computer supports this type of program.
              </li>
              <li>
                <p className="italic">Browser Cookies:</p>
                Cookies are alphanumeric identifiers transferred to your
                computer's hard drive through your browser to enable our systems
                to recognize your browser and track site usage.
              </li>
            </ol>

            <h2 className="bold">How We Use the Information We Collect</h2>
            <p>
              We use the information we collect to provide, maintain, protect,
              and improve our service. This includes tailoring content, offering
              personalized search results and advertisements, and resolving any
              issues you might face.
            </p>
            <p>
              We may use your email address to inform you about our services,
              upcoming changes, or improvements. Information collected from
              cookies and other technologies is used to enhance your user
              experience and the overall quality of our services.
            </p>

            <h2 className="bold">Consent and Compliance</h2>
            <p>
              We will ask for your consent before using information for a
              purpose other than those set out in this Privacy Policy. We will
              also use information as required by law.
            </p>
            <p>
              Nonex Computers Distributors processes personal information on
              servers worldwide. Your personal information may be processed on a
              server located outside the country where you live.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
