import React, { useEffect, useState } from "react";
import "./style.css";
import { ToastContainer } from "react-toastify";
import ShopCart from "../shops/ShopCart";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Products = ({ productItems, heartIcons, addToCart, shopItems }) => {
  const data = [
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714239/samples/nonex%20computers/categories/cat1_izrrvk.png",
      cateName: "Hp Laptops",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714243/samples/nonex%20computers/categories/cat2_zlargg.png",
      cateName: "MacBook Laptops",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714247/samples/nonex%20computers/categories/cat3_iljo1j.png",
      cateName: "Lenovo Laptops",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714251/samples/nonex%20computers/categories/cat4_wxwqhs.png",
      cateName: "Dell Laptops",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714253/samples/nonex%20computers/categories/cat5_h3yyxp.png",
      cateName: "Acer Computers",
    },
    {
      cateImg:
        "https://res.cloudinary.com/dxxhtz2dw/image/upload/v1702714255/samples/nonex%20computers/categories/cat6_eqrzl8.png",
      cateName: "Asus Laptops",
    },
  ];
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryGroup, setSelectedCategoryGroup] = useState(null);

  const { category } = useParams();
  const location = useLocation();
  const { cateName } = location.state || {};
  const { catGroup } = location.state || {};

  useEffect(() => {
    if (cateName !== undefined) {
      setSelectedCategory(cateName);
      setCurrentPage(1);
    } else if (catGroup !== undefined) {
      setSelectedCategoryGroup(catGroup);
    } else {
      setSelectedCategory(category ? decodeURIComponent(category) : null);
    }
  }, [category, cateName, catGroup]);

  const handleCategoryClick = (brand) => {
    setSelectedCategoryGroup(undefined);
    setSelectedCategory(brand);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredProducts = selectedCategoryGroup
    ? shopItems.filter((item) => item.category === selectedCategoryGroup)
    : selectedCategory
    ? shopItems.filter((item) => item.brand === selectedCategory)
    : shopItems;

  const currentProducts = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="shop background">
        <div className="container d_flex smallest-container">
          <div className="category small-screen-display-none">
            <div className="chead d_flex">
              <h1>Brands </h1>
              <h1>Shops </h1>
            </div>
            {data.map((value, index) => {
              return (
                <div
                  className={`box f_flex ${
                    selectedCategory === value.cateName ? "active-cat" : ""
                  }`}
                  key={index}
                  onClick={() => handleCategoryClick(value.cateName)}
                >
                  <img className="" src={value.cateImg} alt="" />
                  <span>{value.cateName}</span>
                </div>
              );
            })}
            <div className="box box2">
              <button onClick={() => handleCategoryClick(null)}>
                View All Brands
              </button>
            </div>
          </div>
          <div className="contentWidth">
            <div className="heading selected-option d_flex">
              <div className="heading-left ">
                <h2>Featured Products</h2>
              </div>
              <div className="selection-option">
                <p className="small-screen-display-none">Select: </p>
                <select
                  value={selectedCategory || ""}
                  onChange={(e) => handleCategoryClick(e.target.value)}
                >
                  <option value="">All Brands</option>
                  {data.map((value, index) => (
                    <option key={index} value={value.cateName}>
                      {value.cateName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="product-content  grid1">
              <ShopCart
                addToCart={addToCart}
                shopItems={currentProducts}
                heartIcons={heartIcons}
              />
            </div>
            <div className="pagination">
              {[
                ...Array(
                  Math.ceil(filteredProducts.length / itemsPerPage)
                ).keys(),
              ].map((number) => (
                <span
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={currentPage === number + 1 ? "active" : ""}
                >
                  {number + 1}
                </span>
              ))}
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Products;
