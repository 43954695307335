import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Data from "../../components/Data";
import Data2 from "../../components/shops/Sdata";
import { useHistory } from "react-router-dom";
// Import necessary icons from react-icons
import {
  FaSearch,
  FaChevronDown,
  FaBars,
  FaTimes,
  FaBorderAll,
} from "react-icons/fa";

const Navbar = () => {
  // Toogle Menu
  const [MobileMenu, setMobileMenu] = useState(false);
  const { productItems } = Data;
  const { shopItems } = Data2;

  const AllProducts = productItems.concat(shopItems);

  const [searchQuery, setSearchQuery] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setIsPopupOpen(!!event.target.value);
  };

  const filteredProducts = AllProducts.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const showPopupResults = searchQuery.length > 1;

  const history = useHistory();

  const handleClick = (e, shopItem) => {
    e.preventDefault();
    setIsPopupOpen(false);
    history.push({
      pathname: "/products-details",
      state: { shopItem },
    });
    if (!e) {
      var e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };

  const searchRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsPopupOpen(false);
        setMobileMenu(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleClick2 = (e, catGroup) => {
    e.preventDefault();
    setMobileMenu(false);
    history.push({
      pathname: "/products",
      state: { catGroup },
    });
    if (!e) {
      var e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };

  return (
    <>
      <header className="header" ref={searchRef}>
        <div className="container d_flex justify-content-areound">
          <div className="catgrories d_flex small-screen-display-none">
            <FaBorderAll />
            <h4>
              <Link to="/">
                {" "}
                Categories <FaChevronDown />
              </Link>
            </h4>
          </div>
          <div className="search-box2 f_flex">
            <FaSearch style={{ marginTop: "13px", marginLeft: "8px" }} />
            <input
              type="text"
              placeholder="Search and select..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <span>All</span>
          </div>
          <div className="navlink">
            <ul
              className={
                MobileMenu ? "nav-links-MobileMenu" : "link f_flex capitalize"
              }
              onClick={() => setMobileMenu(false)}
            >
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li onClick={(e) => handleClick2(e, "laptops")}>
                <a href="#">Laptops</a>
              </li>
              <li onClick={(e) => handleClick2(e, "computers")}>
                <a href="#">Computers</a>
              </li>
              <li onClick={(e) => handleClick2(e, "accessories")}>
                <a href="#">Accesories</a>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>

            <button
              className="toggle"
              onClick={() => setMobileMenu(!MobileMenu)}
            >
              {MobileMenu ? (
                <FaTimes className="close home-btn" />
              ) : (
                <FaBars className="open" />
              )}
            </button>

            <button
              className="toggle"
              onClick={() => setMobileMenu(!MobileMenu)}
            >
              {MobileMenu ? (
                <i className="fas fa-times close home-btn"></i>
              ) : (
                <i className="fas fa-bars open"></i>
              )}
            </button>
          </div>
          {showPopupResults && isPopupOpen && (
            <div className="position-absolute">
              <div className="popup-box">
                {filteredProducts.length === 0 && (
                  <ul>
                    <li>Sorry, We Dont Have Such Product In Our Store</li>
                  </ul>
                )}
                <ul>
                  {filteredProducts.map((product) => (
                    <>
                      <ul>
                        <li
                          onClick={(e) => handleClick(e, product)}
                          key={product.id}
                        >
                          {product.name}
                        </li>
                      </ul>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Navbar;
